
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("frontend-login/app", function(){ return i("frontend-login/app.ts");});
d("frontend-login/config/environment", function(){ return i("frontend-login/config/environment.js");});
d("frontend-login/initializers/web-components", function(){ return i("frontend-login/initializers/web-components.ts");});
d("frontend-login/router", function(){ return i("frontend-login/router.ts");});
d("frontend-login/services/authentication", function(){ return i("frontend-login/services/authentication.ts");});
d("frontend-login/services/fetch", function(){ return i("frontend-login/services/fetch.js");});
d("frontend-login/utils/constant-values", function(){ return i("frontend-login/utils/constant-values.ts");});
d("frontend-login/utils/domain-checks", function(){ return i("frontend-login/utils/domain-checks.ts");});
d("frontend-login/utils/model-from", function(){ return i("frontend-login/utils/model-from.ts");});
d("frontend-login/services/flash-messages", function(){ return i("frontend-login/services/flash-messages.js");});
d("frontend-login/services/page-title", function(){ return i("frontend-login/services/page-title.js");});
d("frontend-login/component-managers/glimmer", function(){ return i("frontend-login/component-managers/glimmer.js");});
d("frontend-login/initializers/app-version", function(){ return i("frontend-login/initializers/app-version.js");});
d("frontend-login/data-adapter", function(){ return i("frontend-login/data-adapter.js");});
d("frontend-login/initializers/ember-data-data-adapter", function(){ return i("frontend-login/initializers/ember-data-data-adapter.js");});
d("frontend-login/adapters/-json-api", function(){ return i("frontend-login/adapters/-json-api.js");});
d("frontend-login/initializers/ember-data", function(){ return i("frontend-login/initializers/ember-data.js");});
d("frontend-login/instance-initializers/ember-data", function(){ return i("frontend-login/instance-initializers/ember-data.js");});
d("frontend-login/serializers/-default", function(){ return i("frontend-login/serializers/-default.js");});
d("frontend-login/serializers/-json-api", function(){ return i("frontend-login/serializers/-json-api.js");});
d("frontend-login/serializers/-rest", function(){ return i("frontend-login/serializers/-rest.js");});
d("frontend-login/services/store", function(){ return i("frontend-login/services/store.js");});
d("frontend-login/transforms/boolean", function(){ return i("frontend-login/transforms/boolean.js");});
d("frontend-login/transforms/date", function(){ return i("frontend-login/transforms/date.js");});
d("frontend-login/transforms/number", function(){ return i("frontend-login/transforms/number.js");});
d("frontend-login/transforms/string", function(){ return i("frontend-login/transforms/string.js");});
d("frontend-login/services/-ensure-registered", function(){ return i("frontend-login/services/-ensure-registered.js");});
d("frontend-login/services/intl", function(){ return i("frontend-login/services/intl.js");});
d("frontend-login/utils/intl/missing-message", function(){ return i("frontend-login/utils/intl/missing-message.js");});
d("frontend-login/container-debug-adapter", function(){ return i("frontend-login/container-debug-adapter.js");});
d("frontend-login/utils/calculate-position", function(){ return i("frontend-login/utils/calculate-position.js");});
d("frontend-login/services/text-measurer", function(){ return i("frontend-login/services/text-measurer.js");});
d("frontend-login/services/alerting", function(){ return i("frontend-login/services/alerting.js");});
d("frontend-login/services/toast", function(){ return i("frontend-login/services/toast.js");});
d("frontend-login/instance-initializers/sentry-performance", function(){ return i("frontend-login/instance-initializers/sentry-performance.js");});
d("frontend-login/services/ajax", function(){ return i("frontend-login/services/ajax.js");});
d("frontend-login/models/base-hotel-market", function(){ return i("frontend-login/models/base-hotel-market.js");});
d("frontend-login/models/compset", function(){ return i("frontend-login/models/compset.js");});
d("frontend-login/models/custom-inflector-rules", function(){ return i("frontend-login/models/custom-inflector-rules.js");});
d("frontend-login/models/featurescontext", function(){ return i("frontend-login/models/featurescontext.js");});
d("frontend-login/models/hotel", function(){ return i("frontend-login/models/hotel.js");});
d("frontend-login/models/hotelinfo", function(){ return i("frontend-login/models/hotelinfo.js");});
d("frontend-login/models/insightpagesfavourite", function(){ return i("frontend-login/models/insightpagesfavourite.js");});
d("frontend-login/models/market", function(){ return i("frontend-login/models/market.js");});
d("frontend-login/models/ota", function(){ return i("frontend-login/models/ota.js");});
d("frontend-login/models/reportconfig", function(){ return i("frontend-login/models/reportconfig.js");});
d("frontend-login/models/reportgroup", function(){ return i("frontend-login/models/reportgroup.js");});
d("frontend-login/models/roomtype", function(){ return i("frontend-login/models/roomtype.js");});
d("frontend-login/models/shopconfig", function(){ return i("frontend-login/models/shopconfig.js");});
d("frontend-login/models/user", function(){ return i("frontend-login/models/user.js");});
d("frontend-login/services/error-tracking", function(){ return i("frontend-login/services/error-tracking.js");});
d("frontend-login/transforms/dayjs", function(){ return i("frontend-login/transforms/dayjs.js");});
d("frontend-login/transforms/hotelname", function(){ return i("frontend-login/transforms/hotelname.js");});
d("frontend-login/transforms/json", function(){ return i("frontend-login/transforms/json.js");});
d("frontend-login/templates/change-email", function(){ return i("frontend-login/templates/change-email.hbs");});
d("frontend-login/controllers/change-email", function(){ return i("frontend-login/controllers/change-email.ts");});
d("frontend-login/routes/change-email", function(){ return i("frontend-login/routes/change-email.ts");});
d("frontend-login/templates/login", function(){ return i("frontend-login/templates/login.hbs");});
d("frontend-login/controllers/login", function(){ return i("frontend-login/controllers/login.ts");});
d("frontend-login/routes/login", function(){ return i("frontend-login/routes/login.ts");});
d("frontend-login/templates/password-reset", function(){ return i("frontend-login/templates/password-reset.hbs");});
d("frontend-login/controllers/password-reset", function(){ return i("frontend-login/controllers/password-reset.js");});
d("frontend-login/routes/password-reset", function(){ return i("frontend-login/routes/password-reset.ts");});
d("frontend-login/controllers/register", function(){ return i("frontend-login/controllers/register.js");});
d("frontend-login/routes/register", function(){ return i("frontend-login/routes/register.ts");});
d("frontend-login/templates/application", function(){ return i("frontend-login/templates/application.hbs");});
d("frontend-login/routes/application", function(){ return i("frontend-login/routes/application.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("frontend-login/instance-initializers/setup-fetch", function(){ return i("frontend-login/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"frontend-login","version":"0.0.0+498afd6e"});
}

