import { applyPolyfills, defineCustomElements } from '@mylighthouse/prism-web/loader';

export async function initialize() {
  // Load @mylighthouse/prism-web
  // Pollyfills are needed since not every browser we target natively supports Shadow DOM
  await applyPolyfills();
  await defineCustomElements();
}

export default {
  initialize,
};
