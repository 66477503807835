import Helper from '@ember/component/helper';

import ENV from 'frontend-login/config/environment';

export interface FormatImageUrlSignature {
  Args: {
    Positional: string[];
  };
  Return: string;
}

export default class FormatImageUrl extends Helper<FormatImageUrlSignature> {
  compute(
    params: FormatImageUrlSignature['Args']['Positional']
  ): FormatImageUrlSignature['Return'] {
    return `${ENV.staticURL}${params.join('')}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-image-url': typeof FormatImageUrl;
  }
}
